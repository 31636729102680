import gnomes from '../../assets/gnomes.webp'
import bird2 from '../../assets/bird2.webp'
import sun from '../../assets/sun.webp'
import { VStack, Flex, Box, Text, Spacer, Image } from '@chakra-ui/react';

export const Section4 = () => {
  return (
    <Flex
      w="100vw"
      h="100vh"
      py={12}
      px={10}
      direction={{ md: "row", base: "column",  }} 
      scrollSnapAlign="center"
      alignItems="center" 
      // justifyContent="center" 
      spacing={{ base: 0, md: 6 }} 
    >
      <Box
        h={{ md: "90%", base: "full" }}
        w={{md: "50%", base: "full"}}
        display="flex"
        flexDir={"column"}
        spacing={12}
        alignItems="center"
        justifyContent="center" 
      >
        <Image src={gnomes} w={{md: "70%", base: "70%"}} />
      </Box>
      <Box
        h={{ md: "100%", base: "full" }}
        w={{md: "50%", base: "full"}}
        display="flex"
        flexDir={"column"}
        spacing={12}
        alignItems="center"
        justifyContent="center" 
      >
        <Image w="45%" src={sun}/>
        <Spacer/>
        <VStack w="100%" spacing={10} pb={10}>
          <Text align="center" w="80%" fontSize="30" whiteSpace="pre-line">
            It was nice to feel the sun and the wind. 
          </Text>
          <Text align="center" w="80%" fontSize="30" whiteSpace="pre-line">
            I met many new friends.
          </Text>
          <Image src={bird2} alignSelf="left" w={{md: "20%", base: "70%"}} pt={4} />
        </VStack>
      </Box>
    </Flex>
  )
}
