import sapling2 from '../../assets/sapling2.webp'
import { VStack, Text, Image } from '@chakra-ui/react'

export const MSection1 = () => {
  return (
    <VStack
      w="100vw"
      h="100vh"
      px={10}
      scrollSnapAlign="center"
      alignItems="center" 
      justifyContent="center" 
      display="flex"
      flexDir={"column"}
      spacing={12}
    >
      <Text fontSize="30" whiteSpace="pre-line" align="center">
        Long ago, I lived in a nursery with many saplings like me.
      </Text>
      <Image alignSelf="center" src={sapling2} w="50%" />
      <Text fontSize="30" whiteSpace="pre-line" align="center">
        I wished to someday live outdoors with the sun and birds.
      </Text>
    </VStack>
  )
}
