
import planting4 from '../../assets/planting4.webp'
import { VStack, Text, Image } from '@chakra-ui/react';

export const MSection35 = () => {
  return (
    <VStack
      w="100vw"
      h="100vh"
      px={10}
      scrollSnapAlign="center"
      alignItems="center" 
      justifyContent="center" 
      display="flex"
      flexDir={"column"}
      spacing={12}
    >
    <Image src={planting4} w="100%" />
    </VStack>
  )
}
