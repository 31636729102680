import { VStack, Text } from '@chakra-ui/react'

export const MSection5 = () => {
  return (
    <VStack
      w="100vw"
      h="100vh"
      px={10}
      scrollSnapAlign="center"
      alignItems="center" 
      justifyContent="center" 
      display="flex"
      flexDir={"column"}
      spacing={12}
    >
      <Text align="center" fontSize="30" whiteSpace="pre-line">
        Year by year, I grew.
      </Text>
    </VStack>
  )
}
