import { ChakraProvider } from '@chakra-ui/react';
import { LandingPage } from './pages/LandingPage';
import '@fontsource/gamja-flower';
import theme from './theme'

function App() {
  return (
    <ChakraProvider theme={theme}>
      <LandingPage/>
    </ChakraProvider>
  );
}

export default App;
