import main from '../../assets/main.webp'
import React from 'react'
import { Box, Image, Center } from '@chakra-ui/react'

export const StartSection = () => {
  return (
    <Box w="100vw" h='100vh'>
      <Center
        scrollSnapAlign="center"
        w="100%"
      >
        <Box h="100vh" w="80%" position="relative">
          <Image position="absolute" src={main} h="100%" t="0" b="0" zIndex={-1}/>
        </Box>
      </Center>
    </Box>
  )
}
