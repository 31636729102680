import mmain from '../../assets/mmain.png'
import React, { useRef, useEffect } from 'react'
import { Box, Image, Center } from '@chakra-ui/react';

export const MStartSection = () => {
  const boxRef = useRef(null);

  useEffect(() => {
    if (boxRef.current) {
      boxRef.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
    }
  }, []);

  return (
    <Box ref={boxRef} position="relative" w="100%" scrollSnapAlign="end">
      <Center
      top="0"
      right="0"
      bottom="0"
      left="0"
      h="100vh"
      alignItems="end"
      justifyContent="end"
    >
      <Image
        position="absolute"
        src={mmain}
        w="100%"
        h="100%"
        objectFit="cover"
        objectPosition="bottom right"
      />
    </Center>
    </Box>
  )
}
