import kids2 from '../../assets/kids2.webp'
import { VStack, Text, Image } from '@chakra-ui/react'

export const MSection25 = () => {
  return (
    <VStack
      w="100vw"
      h="100vh"
      px={10}
      scrollSnapAlign="center"
      alignItems="center" 
      justifyContent="center" 
      display="flex"
      flexDir={"column"}
      spacing={12}
    >
      <Image src={kids2} w="90%" />
      <Text fontSize="30" whiteSpace="pre-line" align="center">
        They looked very friendly. I liked them very much.
      </Text>
    </VStack>
  )
}
