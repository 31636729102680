import sun from '../../assets/sun.webp'
import bird2 from '../../assets/bird2.webp'
import { VStack, Text, Image } from '@chakra-ui/react'

export const MSection4 = () => {
  return (
    <VStack
      w="100vw"
      h="100vh"
      px={10}
      scrollSnapAlign="center"
      alignItems="center" 
      justifyContent="center" 
      display="flex"
      flexDir={"column"}
      spacing={12}
    >
      <Image src={sun} w="70%" pb={10} />
      <Text align="center" pt={10} fontSize="30" whiteSpace="pre-line">
        It was nice to feel the sun and the wind. 
      </Text>
      <Image src={bird2} alignSelf="left" w="30%" />
    </VStack>
  )
}
