import { VStack, Text } from '@chakra-ui/react'

export const MSection2 = () => {
  return (
    <VStack
      w="100vw"
      h="100vh"
      px={10}
      scrollSnapAlign="center"
      alignItems="center" 
      justifyContent="center" 
      display="flex"
      flexDir={"column"}
      spacing={12}
    >
      <Text fontSize="30" whiteSpace="pre-line" align="center">
        One day, Alexis and Thomas came to visit. 
      </Text>
    </VStack>
  )
}
