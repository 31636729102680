import { extendTheme } from '@chakra-ui/react'

const theme = extendTheme({
  fonts: {
    heading: `'Gamja Flower', sans-serif`,
    body: `'Gamja Flower', sans-serif`,
  },
  colors: { 
    primaryFontColor: "#333300", 
    secondaryFontColor: "gray"
} 
})

export default theme