import planting4 from '../../assets/planting4.webp'
import bucketearth from '../../assets/bucketearth.webp'
import { VStack, Flex, Box, Text, Image } from '@chakra-ui/react';

export const Section3 = () => {
  return (
    <Flex
      w="100vw"
      h="100vh"
      py={12}
      px={10}
      direction={{ md: "row", base: "column",  }} 
      scrollSnapAlign="center"
      alignItems="center" 
      justifyContent="center" 
      spacing={{ base: 0, md: 6 }} 
    >
      <VStack
        h={{ md: "90%", base: "full" }}
        w={{md: "50%", base: "full"}}
        display="flex"
        flexDir={"column"}
        spacing={12}
        alignItems="center"
        justifyContent="center" 
      >
        <Text fontSize="30" whiteSpace="pre-line" w="80%" align="center">
          "We will plant you!" they shouted excitedly.
        </Text>
        <Text fontSize="30" whiteSpace="pre-line" w="80%" align="center" pb={10}>
          They brought me out to the park, dug a hole, and buried me in the ground.
        </Text>
        <Image src={bucketearth} pt={10} w={{md: "30%", base: "70%"}} />
      </VStack>
      <Box
        w="50%" 
        display="flex"
        flexDir={"column"}
        spacing={10}
        alignItems="center"
        justifyContent="center" 
      >
        <Image src={planting4} w={{md: "70%", base: "70%"}} />
      </Box>
    </Flex>
  )
}
