
import gnome from '../assets/gnome.webp'
import React, { useState, useEffect } from 'react'
import { VStack, Box, Image, Show } from '@chakra-ui/react'
import { keyframes } from "@emotion/react"
import { StartSection } from '../components/sections/StartSection'
import { Section1 } from '../components/sections/Section1'
import { Section2 } from '../components/sections/Section2'
import { Section3 } from '../components/sections/Section3'
import { Section4 } from '../components/sections/Section4'
import { Section5 } from '../components/sections/Section5'
import { Section6 } from '../components/sections/Section6'
import { EndSection } from '../components/sections/EndSection'

import { MStartSection } from '../components/mobilesections/MStartSection'
import { MSection1 } from '../components/mobilesections/MSection1'
import { MSection15 } from '../components/mobilesections/MSection15'
import { MSection2 } from '../components/mobilesections/MSection2'
import { MSection25 } from '../components/mobilesections/MSection25'
import { MSection3 } from '../components/mobilesections/MSection3'
import { MSection35 } from '../components/mobilesections/MSection35'
import { MSection4 } from '../components/mobilesections/MSection4'
import { MSection45 } from '../components/mobilesections/MSection45'
import { MSection5 } from '../components/mobilesections/MSection5'
import { MSection55 } from '../components/mobilesections/MSection55'
import { MSection6 } from '../components/mobilesections/MSection6'
import { MSection65 } from '../components/mobilesections/MSection65'
import { MEndSection } from '../components/mobilesections/MEndSection'

const bounceAnimation = keyframes`
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(15px); }
`

const DesktopSections = ({ setIsIndicatorVisible }) => (
  <VStack w="100%">  
    <StartSection   />
    <Section1 />
    <Section2 />
    <Section3 />
    <Section4 />
    <Section5 />
    <Section6 />
    <EndSection setIsScrollVisible={setIsIndicatorVisible} />
  </VStack>
)

const MobileSections = ({ setIsIndicatorVisible }) => (
  <VStack w="100%">  
    <MStartSection/>
    <MSection1 />
    <MSection15 />
    <MSection2 />
    <MSection25 />
    <MSection3 />
    <MSection35 />
    <MSection4 />
    <MSection45 />
    <MSection5 />
    <MSection55 />
    <MSection6 />
    <MSection65 />
    <MEndSection setIsScrollVisible={setIsIndicatorVisible} />
  </VStack>
)

export const LandingPage = () => {
  const [isIndicatorVisible, setIsIndicatorVisible] = useState(false);

  useEffect(()=> {
    setTimeout(()=> {
      setIsIndicatorVisible(true)
    }, 500)
  }, [])

  const ScrollIndicator = ()  => {
    return (
      <Box position="fixed" right="10px" bottom="30px" zIndex="10">
        <Image boxSize="10" src={gnome} css={{
          animation: `${bounceAnimation} 1s ease-in-out infinite`
        }}/>
      </Box>
    );
  }

  return (
      <Box position="fixed"  overflowY="scroll" height={{ base: "100%", md: "100vh"}} width="100vw" scrollSnapType="y mandatory">
        {isIndicatorVisible &&<ScrollIndicator />}
        <Show above='md'>
          <DesktopSections setIsIndicatorVisible={setIsIndicatorVisible}/>
        </Show>
        <Show below='md'>
          <MobileSections setIsIndicatorVisible={setIsIndicatorVisible}/>
        </Show>
      </Box>
  );
}
