import sapling2 from '../../assets/sapling2.webp'
import treewithsun from '../../assets/treewithsun.webp'
import { VStack, Text, Image } from '@chakra-ui/react';

export const MSection15 = () => {
  return (
    <VStack
      w="100vw"
      h="100vh"
      px={10}
      scrollSnapAlign="center"
      alignItems="center" 
      justifyContent="center" 
      display="flex"
      flexDir={"column"}
      spacing={12}
    >
      <Image src={treewithsun} w="100%" />
    </VStack>
  )
}
