
import bucketearth from '../../assets/bucketearth.webp'
import { VStack, Text, Image } from '@chakra-ui/react';

export const MSection3 = () => {
  return (
    <VStack
      w="100vw"
      h="100vh"
      px={10}
      scrollSnapAlign="center"
      alignItems="center" 
      justifyContent="center" 
      display="flex"
      flexDir={"column"}
      spacing={12}
    >
      <Text fontSize="30" whiteSpace="pre-line" align="center">
        "We will plant you!" they shouted excitedly.
      </Text>
      <Image src={bucketearth} pt={10} w="70%" />
      <Text fontSize="30" whiteSpace="pre-line" align="center" pb={10}>
        They brought me out to the park, dug a hole, and buried me in the ground.
      </Text>
    </VStack>
  )
}
