import gnomes from '../../assets/gnomes.webp'
import { VStack, Text, Image } from '@chakra-ui/react'

export const MSection45 = () => {
  return (
    <VStack
      w="100vw"
      h="100vh"
      px={10}
      scrollSnapAlign="center"
      alignItems="center" 
      justifyContent="center" 
      display="flex"
      flexDir={"column"}
      spacing={12}
    >
      <Image src={gnomes} w="90%" />
      <Text align="center" fontSize="30" whiteSpace="pre-line">
        I met many new friends.
      </Text>
    </VStack>
  )
}
