import kids2 from '../../assets/kids2.webp'
import { VStack, Flex, Box, Text, Image } from '@chakra-ui/react';

export const Section2 = () => {
  return (
    <Flex
      w="100vw"
      h="100vh"
      py={12}
      px={10}
      direction={{ md: "row", base: "column",  }} 
      scrollSnapAlign="center"
      spacing={{ base: 0, md: 6 }} 
    >
      <Box
        w={{md: "50%", base: "100%"}} 
        display="flex"
        flexDir={"column"}
        spacing={10}
        alignItems="center"
        justifyContent="center" 
      >
        <Image src={kids2} w={{md: "70%", base: "70%"}} />
      </Box>
      <VStack
        w={{md: "50%", base: "100%"}}
        display="flex"
        flexDir={"column"}
        justifyContent="center"
        spacing={12}
      >
        <Text fontSize="30" whiteSpace="pre-line" w="80%" align="center">
          One day, Alexis and Thomas came to visit. 
        </Text>
        <Text fontSize="30" whiteSpace="pre-line" w="80%" align="center">
          They looked very friendly. I liked them very much.
        </Text>
      </VStack>
    </Flex>
  )
}
