import sapling2 from '../../assets/sapling2.webp'
import treewithsun from '../../assets/treewithsun.webp'
import { VStack, Flex, Box, Text, Spacer, Image } from '@chakra-ui/react';

export const Section1 = () => {
  return (
    <Flex
      w="100vw"
      h="100vh"
      py={12}
      px={10}
      direction={{ md: "row", base: "column",  }} 
      scrollSnapAlign="center"
      alignItems="center" 
      justifyContent="center" 
      spacing={{ base: 0, md: 6 }} 
    >
      <Box
        h={{ md: "90%", base: "full" }}
        w={{md: "50%", base: "full"}}
        display="flex"
        flexDir={"column"}
        spacing={12}
        alignItems="center"
        justifyContent="center" 
      >
        <Text w="80%" fontSize="30" whiteSpace="pre-line" align="center">
          Long ago, I lived in a nursery with many<br/> saplings like me.
        </Text>
        <Spacer/>
        <Image alignSelf="center" src={sapling2} w="35%" />
        <Spacer/>
        <Text w="80%" fontSize="30" whiteSpace="pre-line" align="center">
          I wished to someday live outdoors with the sun, the earth and birds.
        </Text>
      </Box>
      <VStack
        w="50%" 
        display="flex"
        flexDir={"column"}
        justifyContent="center" 
      >
        <Image src={treewithsun} w="70%" />
      </VStack>
    </Flex>
  )
}
