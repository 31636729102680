import picnic6 from '../../assets/picnic6.webp'
import chair from '../../assets/chair.webp'
import picnicbasket6 from '../../assets/picnicbasket7.webp'
import { VStack, Flex, Box, Text, Image, HStack } from '@chakra-ui/react';

export const Section6 = () => {
  return (
    <Flex
      w="100vw"
      h="100vh"
      py={12}
      px={10}
      direction={{ md: "row", base: "column",  }} 
      scrollSnapAlign="center"
      alignItems="center" 
      spacing={{ base: 0, md: 6 }} 
    >
      <Box
        h={{ md: "90%", base: "full" }}
        w={{md: "50%", base: "full"}}
        display="flex"
        flexDir={"column"}
        spacing={12}
        alignItems="center"
        justifyContent="center" 
      >
        <Image src={picnic6} w={{md: "70%", base: "70%"}} />
      </Box>
      <Box
        h={{ md: "90%", base: "full" }}
        w={{md: "50%", base: "full"}}
        display="flex"
        flexDir={"column"}
        spacing={12}
        alignItems="center"
        justifyContent="center" 
      >
        <VStack spacing={10} alignItems="center" w="100%">
          <Text align="center" w="80%" fontSize="30" whiteSpace="pre-line">
            Alexis and Thomas came to visit often.
          </Text>
          <Text align="center" w="80%" fontSize="30" whiteSpace="pre-line" pb={10}>
            And they always carried lots of good food in hand.
          </Text>
          <HStack spacing={10} justifyContent="center"> 
            <Image src={chair} w={{md: "38%", base: "70%"}} py={12} />
            <Image src={picnicbasket6} w={{md: "33%", base: "70%"}} pt={10} alignSelf="center"/>
          </HStack>
        </VStack>
      </Box>
    </Flex>
  )
}
