import bird from '../../assets/bird.webp'
import bikes2 from '../../assets/bikes2.webp'
import omtm from '../../assets/omtm.webp'
import aboutus from '../../assets/aboutus.webp'
import sign from '../../assets/sign.webp'
import Celebration from '../../assets/Celebration.json'
import Lottie from 'lottie-react'
import React, { useState, useEffect } from 'react'
import { VStack, Stack, Slide, Box, Text, Link, Heading, UnorderedList, ListItem, ModalContent, ModalCloseButton, ModalBody, Image, Modal, Center, Button, useDisclosure } from '@chakra-ui/react';
import { keyframes } from "@emotion/react"

const bounceAnimation = keyframes`
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(10px); }
`;

export const MEndSection = ({setIsScrollVisible}) => {
  const { isOpen: isOpenModal, onClose: onCloseModal, onOpen: onOpenModal } = useDisclosure({ defaultIsOpen: false })

  const [isBirdVisible, setIsBirdVisible] = useState(false)
  
  const SeeMoreModal = () => {
    const [showPresent, setShowPresent] = useState(true)

    useEffect(() => {
      setTimeout(() => setShowPresent(false), 3000)
    }, [])
    
    
    return (
      <Modal onClose={onCloseModal} size={'full'} isOpen={isOpenModal} position="relative">
        { showPresent 
          ? 
          <ModalContent justifyContent={"center"} alignContent={"center"}>
            <Lottie animationData={Celebration}/>
          </ModalContent> 
          : 
          <ModalContent p={0} alignItems="center">
            <ModalCloseButton m={6}/>
            <ModalBody py={10} w={"90%"}>
              <VStack spacing={4} align="start" pb={10}>
                <Heading>Here's your present!</Heading>
                <UnorderedList>
                  <ListItem fontSize={24}>Pick the location and date</ListItem>
                  <ListItem fontSize={24}>We go down in person</ListItem>
                  <ListItem fontSize={24}>We dig a hole</ListItem>
                  <ListItem fontSize={24}>We plant a tree</ListItem>
                  <ListItem fontSize={24}>We visit from time to time!</ListItem>
                </UnorderedList>
                <Heading pt={4}>About the tree</Heading>
                <Stack
                  direction='column'
                  spacing={4}
                >
                  <Text textAlign={"justify"} fontSize={24}>This tree is part of the <Link color='teal.500' href="https://www.nparks.gov.sg/treessg/one-million-trees-movement" isExternal>Plant-A-Tree</Link> movement to plant a million new trees in Singapore over 10 years. More than 600,000 trees have been planted to date in parks around Singapore.</Text>
                  <Image alignSelf="center" src={omtm} w="40%"/>
                </Stack>
                <Heading pt={4}>Proud Plantrents</Heading>
                <Image maxW="60%" pt={4} src={aboutus} alignSelf={"center"}/>
              </VStack>
              <Image maxW={"10%"} src={sign} alignSelf={"right"} position="absolute" bottom={6} right={6}/>
            </ModalBody>
          </ModalContent>
        } 
      </Modal>
    )
  }


  const useLastSectionObserver = (onReachLastSection, onLeaveLastSection) => {
    useEffect(() => {
      const target = document.getElementById('last-section');
  
      if (target) {
        const observer = new IntersectionObserver((entries) => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              onReachLastSection();
            } else {
              onLeaveLastSection();
            }
          });
        }, {
        });
  
        observer.observe(target);
  
        // Clean up
        return () => observer.disconnect();
      }
    }, [onReachLastSection, onLeaveLastSection]);
  }

  const handleReachLastSection = () => {
    setIsScrollVisible(false)
    setTimeout(()=> setIsBirdVisible(true), 2000)
  }
  
  const handleLeaveLastSection = () => {
    setTimeout(()=> setIsScrollVisible(true), 1000)
    setIsBirdVisible(false)
  }

  useLastSectionObserver(handleReachLastSection, handleLeaveLastSection)


  return (
    <Box position="relative" w="100vw" h='100vh' id="last-section">
    <SeeMoreModal/>
    <Slide style={{ maxWidth: "15%" }} direction='right' in={isBirdVisible} transition={{enter: {duration: 1.5}}}>
      <VStack align="right" position="absolute" w="15" right={0} bottom={5} css={{
        animation: `${bounceAnimation} 1s ease-in-out infinite`
      }} onClick={onOpenModal} >
        <Image src={bird} w="100%" alignSelf="end"/>
        <Button variant="link" fontSize="15">Click me</Button>     
      </VStack>
    </Slide>
    <Center
      scrollSnapAlign="center"
      top="0"
      right="0"
      bottom="0"
      left="0"
      h="100vh"
      alignItems="center"
      justifyContent="center"
    >
      <VStack textAlign="center" w="90%" spacing={10}>
      <Image src={bikes2} w={"100%"} />
        <Box minH={"150px"}>
          <Text fontSize={30} color="#003333" pt={10}>And this is the story of how I came to be!</Text>
        </Box>
      </VStack>
    </Center>
    </Box>
  )
}
