import skateboarding2 from '../../assets/skateboarding2.webp'
import { VStack, Image } from '@chakra-ui/react'

export const MSection55 = () => {
  return (
    <VStack
      w="100vw"
      h="100vh"
      px={10}
      scrollSnapAlign="center"
      alignItems="center" 
      justifyContent="center" 
      display="flex"
      flexDir={"column"}
      spacing={12}
    >
      <Image src={skateboarding2} w="100%" />
    </VStack>
  )
}
