import chair from '../../assets/chair.webp'
import picnicbasket7 from '../../assets/picnicbasket7.webp'
import { VStack, Image, Text, HStack } from '@chakra-ui/react'

export const MSection6 = () => {
  return (
    <VStack
      w="100vw"
      h="100vh"
      px={10}
      scrollSnapAlign="center"
      alignItems="center" 
      justifyContent="center" 
      display="flex"
      flexDir={"column"}
      spacing={12}
    >
      <Text align="center" fontSize="30" whiteSpace="pre-line">
        Alexis and Thomas came to visit often.
      </Text>
      <Text align="center" fontSize="30" whiteSpace="pre-line">
        And they always carried lots of good food in hand.
      </Text>
      <HStack spacing={4} justifyContent="center"> 
        <Image src={chair} w="50%" pl={4}/>
        <Image src={picnicbasket7} w="50%" alignSelf="center"/>
      </HStack>
    </VStack>
  )
}
